// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/atlassian/pipelines/agent/build/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-thanx-js": () => import("/opt/atlassian/pipelines/agent/build/src/templates/thanx.js" /* webpackChunkName: "component---src-templates-thanx-js" */),
  "component---src-templates-services-js": () => import("/opt/atlassian/pipelines/agent/build/src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-pages-404-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-how-we-help-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/how-we-help/index.js" /* webpackChunkName: "component---src-pages-how-we-help-index-js" */),
  "component---src-pages-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-tools-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-page-seo-scanner-index-js": () => import("/opt/atlassian/pipelines/agent/build/src/pages/tools/page-seo-scanner/index.js" /* webpackChunkName: "component---src-pages-tools-page-seo-scanner-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/atlassian/pipelines/agent/build/.cache/data.json")

